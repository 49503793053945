// src/pages/ModularCourseEditor.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeadingModule from '../components/editor/HeadingModule';
import TextModule from '../components/editor/TextModule';
import VideoModule from '../components/editor/VideoModule';
import QuizModule from '../components/editor/QuizModule';
import ChatModule from '../components/editor/ChatModule';
import ImageModule from '../components/editor/ImageModule';
import CommentsModule from '../components/editor/CommentsModule';
import '../styles/ModularCourseEditor.css';

const ModularCourseEditor = () => {
    const { courseId } = useParams();
    const [courseData, setCourseData] = useState({ name: '', description: '', toc: [] });
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        fetchCourseData();
    }, [courseId]);

    const fetchCourseData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Course/fetchCourse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ id: courseId })
            });
            const data = await response.json();
            setCourseData(data);
            setModules(data.toc || []);
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };

    const handleModuleAddition = (moduleType) => {
        const newModule = { type: moduleType, content: '' };
        setModules([...modules, newModule]);
    };

    const handleChapterSelect = (chapter) => {
        setSelectedChapter(chapter);
        setIsSidebarOpen(false);
    };

    return (
        <div className="course-editor-wrapper">
            <button className="toggle-sidebar-btn" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                {isSidebarOpen ? 'Hide Chapters' : 'Show Chapters'}
            </button>

            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <h2>Chapters</h2>
                <ul>
                    {courseData.toc.map((chapter, index) => (
                        <li key={index} onClick={() => handleChapterSelect(chapter)}>
                            {chapter.title}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="editor-content">
                <h1>{courseData.name}</h1>
                <div className="module-container">
                    {modules.map((module, index) => {
                        switch (module.type) {
                            case 'Heading':
                                return <HeadingModule key={index} data={module.content} />;
                            case 'Text':
                                return <TextModule key={index} data={module.content} />;
                            case 'Video':
                                return <VideoModule key={index} data={module.content} />;
                            case 'Quiz':
                                return <QuizModule key={index} data={module.content} />;
                            case 'Chat':
                                return <ChatModule key={index} data={module.content} />;
                            case 'Image':
                                return <ImageModule key={index} data={module.content} />;
                            case 'Comments':
                                return <CommentsModule key={index} data={module.content} />;
                            default:
                                return null;
                        }
                    })}
                </div>

                <div className="module-add-buttons">
                    <button onClick={() => handleModuleAddition('Heading')}>Add Heading</button>
                    <button onClick={() => handleModuleAddition('Text')}>Add Text</button>
                    <button onClick={() => handleModuleAddition('Video')}>Add Video</button>
                    <button onClick={() => handleModuleAddition('Quiz')}>Add Quiz</button>
                    <button onClick={() => handleModuleAddition('Chat')}>Add Chat</button>
                    <button onClick={() => handleModuleAddition('Image')}>Add Image</button>
                    <button onClick={() => handleModuleAddition('Comments')}>Add Comments</button>
                </div>
            </div>
        </div>
    );
};

export default ModularCourseEditor;
