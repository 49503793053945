import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Pricing.css';

const Pricing = () => {
    const navigate = useNavigate();

    const handleJoinNow = () => {
        // Handle the logic for joining or navigating to a different page.
        // For example, if the user is not logged in, navigate to the signup page:
        navigate('/signup');
    };

    return (
        <div className="container py-5">
            <div className="row">

                {/* Free Plan */}
                <div className="col-md-3 card-container">
                    <div className="card text-center">
                        <div className="card-header">
                            <h5 className="card-title">Free</h5>
                        </div>
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">Join a community</h6>
                            <ul className="list-unstyled">
                                <li>Access all features in your community</li>
                                <li>Participate with the community</li>
                                <li>Access free courses</li>
                            </ul>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={handleJoinNow}>Join now</button>
                        </div>
                    </div>
                </div>

                {/* Community Plan */}
                <div className="col-md-3 card-container">
                    <div className="card text-center">
                        <div className="card-header">
                            <h5 className="card-title">Community</h5>
                        </div>
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">Create your own community</h6>
                            <ul className="list-unstyled">
                                <li>Chat &amp; Messaging</li>
                                <li>Rich member profiles</li>
                                <li>Option to charge</li>
                                <li>Access to free courses</li>
                                <li>Affiliate system</li>
                            </ul>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={handleJoinNow}>Join now</button>
                        </div>
                    </div>
                </div>

                {/* Courses Plan */}
                <div className="col-md-3 card-container">
                    <div className="card text-center">
                        <div className="card-header">
                            <h5 className="card-title">Courses</h5>
                        </div>
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">Create and sell your own courses</h6>
                            <ul className="list-unstyled">
                                <li>Create your own courses and earn</li>
                                <li>External integrations</li>
                                <li></li>
                            </ul>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={handleJoinNow}>Join now</button>
                        </div>
                    </div>
                </div>

                {/* Enterprise Plan */}
                <div className="col-md-3 card-container">
                    <div className="card text-center">
                        <div className="card-header">
                            <h5 className="card-title">Enterprise</h5>
                        </div>
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2 text-muted">For Businesses</h6>
                            <ul className="list-unstyled">
                                <li>Feature 1</li>
                                <li>Feature 2</li>
                                <li>Feature 3</li>
                            </ul>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={handleJoinNow}>Join now</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Pricing;
