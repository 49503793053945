import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const CourseEditor = () => {
    const { courseId, chapterId } = useParams();
    const [courseData, setCourseData] = useState({ name: '', description: '', toc: [] });
    const [autosaveTimer, setAutosaveTimer] = useState(null);
    const editorRef = useRef(null);
    const [quill, setQuill] = useState(null);

    useEffect(() => {
        loadCourse(courseId);
        startAutosave();

        return () => {
            if (autosaveTimer) {
                clearInterval(autosaveTimer);
            }
        };
    }, [courseId]);

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                const index = document.querySelector('.toc-item.clicked-toc-item')?.dataset.index;
                if (index !== undefined) {
                    const updatedToc = [...courseData.toc];
                    updatedToc[index].content = quill.root.innerHTML;
                    setCourseData({ ...courseData, toc: updatedToc });
                }
            });
        }
    }, [quill, courseData]);

    const loadCourse = async (courseId) => {
        if (courseId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/Course/fetchCourse`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ id: courseId })
                });
                const data = await response.json();
                setCourseData(data);
                initQuill();
            } catch (error) {
                console.error('Error loading course:', error);
            }
        } else {
            setCourseData({ name: '', description: '', toc: [] });
            initQuill();
        }
    };

    const initQuill = () => {
        if (editorRef.current && !quill) {
            const editorQuill = new Quill(editorRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        ['link', 'image', 'video', 'formula'],
                        [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean']
                    ]
                }
            });
            setQuill(editorQuill);
        }
    };

    const moveChapter = (index, direction) => {
        const updatedToc = [...courseData.toc];
        const [movedChapter] = updatedToc.splice(index, 1);

        if (direction === 'up') {
            updatedToc.splice(index - 1, 0, movedChapter);
        } else if (direction === 'down') {
            updatedToc.splice(index + 1, 0, movedChapter);
        }

        setCourseData({ ...courseData, toc: updatedToc });
    };

    const startAutosave = () => {
        const timer = setInterval(() => {
            saveCourse();
        }, 30000); // Autosave every 30 seconds

        setAutosaveTimer(timer);
    };

    const saveCourse = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Autosave/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ data: courseData })
            });
            const result = await response.json();
            console.log('Course saved successfully:', result);
        } catch (error) {
            console.error('Error saving course:', error);
        }
    };

    const showEditModal = (index) => {
        const chapter = courseData.toc[index];
        document.getElementById('chapterName').value = chapter.name;
        document.getElementById('chapterDescription').value = chapter.description;
        document.getElementById('saveChapterBtn').onclick = () => saveChapter(index);
    };

    const saveChapter = (index) => {
        const updatedToc = [...courseData.toc];
        updatedToc[index].name = document.getElementById('chapterName').value;
        updatedToc[index].description = document.getElementById('chapterDescription').value;

        setCourseData({ ...courseData, toc: updatedToc });
        saveCourse();
        document.getElementById('addChapterModal').modal('hide');
    };

    const handleChapterClick = (index) => {
        const chapter = courseData.toc[index];
        document.querySelectorAll('.toc-item').forEach(item => item.classList.remove('clicked-toc-item'));
        document.querySelectorAll('.toc-item')[index].classList.add('clicked-toc-item');
        if (quill && chapter.content) {
            quill.root.innerHTML = chapter.content;
        }
    };

    const renderCommandBar = () => {
        return (
            <>
                <button id="addChapterBtn" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#addChapterModal">
                    <i className="fa-solid fa-plus fa-2x"></i><br />Add Chapter
                </button>
                <button id="uploadCoverBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-upload fa-2x"></i><br />Upload cover
                </button>
                <button id="setPriceBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-money-bill fa-2x"></i><br />Economy
                </button>
                <button id="setScheduleBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-calendar fa-2x"></i><br />Set Schedule
                </button>
                <button id="setTagsBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-tags fa-2x"></i><br />Tags & Category
                </button>
                <button id="setLanguageBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-globe fa-2x"></i><br />Language
                </button>
                <button id="duplicateBtn" className="btn btn-light ms-1">
                    <i className="fa-solid fa-copy fa-2x"></i><br />Duplicate
                </button>
                <button id="deleteBtn" className="btn btn-danger ms-1">
                    <i className="fa-solid fa-trash fa-2x"></i><br />Delete
                </button>
                <button id="saveCourseBtn" className="btn btn-success ms-1">
                    <i className="fa-solid fa-floppy-disk fa-2x"></i><br />Save
                </button>
            </>
        );
    };

    return (
        <div className="container-fluid mt-4">
            <div className="row">
                <div className="col-md-10">
                    <h1
                        id="courseTitle"
                        contentEditable
                        onInput={(e) => setCourseData({ ...courseData, name: e.currentTarget.innerText })}
                        data-placeholder="Enter your title here"
                    >
                        {courseData.name}
                    </h1>
                    <p
                        id="courseDescription"
                        contentEditable
                        onInput={(e) => setCourseData({ ...courseData, description: e.currentTarget.innerText })}
                        data-placeholder="Enter your description here"
                    >
                        {courseData.description}
                    </p>
                    <div id="commandBar" className="mb-3">
                        {renderCommandBar()}
                    </div>
                    <div id="courseEditorContainer">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Table of Contents</h2>
                                <ul className="list-group tocList">
                                    {courseData.toc.map((chapter, index) => (
                                        <li key={index} className="list-group-item toc-item" data-index={index} onClick={() => handleChapterClick(index)}>
                                            <button
                                                className="btn btn-sm btn-outline-secondary move-up-btn"
                                                disabled={index === 0}
                                                onClick={(e) => { e.stopPropagation(); moveChapter(index, 'up'); }}
                                            >↑</button>
                                            <button
                                                className="btn btn-sm btn-outline-secondary ms-1 me-1 move-down-btn"
                                                disabled={index === courseData.toc.length - 1}
                                                onClick={(e) => { e.stopPropagation(); moveChapter(index, 'down'); }}
                                            >↓</button>
                                            <button
                                                className="btn btn-sm btn-outline-secondary ms-1 me-1 edit-chapter-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#addChapterModal"
                                                onClick={(e) => { e.stopPropagation(); showEditModal(index); }}
                                            >✎</button>
                                            {chapter.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-8" id="chapterEditor">
                                <span id="instruction" style={{ fontSize: '24px', marginTop: '50px', display: 'block' }}>
                                    <i className="fa-solid fa-arrow-left"></i> Click a table of contents chapter to start editing your course
                                </span>
                                <div ref={editorRef} id="editor" className="editor chapterModule"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2" id="infoBox">
                    <div className="card">
                        <div className="card-body">
                            <img src="https://via.placeholder.com/150" className="card-img-top" id="courseCover" alt="Course cover" />
                            <h5 className="card-title">Course Info</h5>
                            <p className="card-text">Price: $0</p>
                            <p className="card-text">Category: General</p>
                            <p className="card-text">Tags: #general #programming</p>
                            <p className="card-text">Language: English</p>
                            <p className="card-text">Schedule: 2022-01-01 to 2022-12-31</p>
                            <p className="card-text">Discount: 0%</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Chapter Modal */}
            <div className="modal fade" id="addChapterModal" tabIndex="-1" aria-labelledby="addChapterModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addChapterModalLabel">Add Chapter</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="addChapterForm">
                                <div className="mb-3">
                                    <label htmlFor="chapterName" className="form-label">Chapter Name</label>
                                    <input type="text" className="form-control" id="chapterName" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="chapterDescription" className="form-label">Chapter Description</label>
                                    <textarea className="form-control" id="chapterDescription" rows="3" required></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" id="saveChapterBtn" className="btn btn-primary">Save Chapter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseEditor;
