// src/components/community/Feed.js

import React, { useEffect, useState } from 'react';
import community from "../../pages/Community";

const Feed = ({ communityId }) => {
    const [feedItems, setFeedItems] = useState([]);
    const [newPost, setNewPost] = useState('');

    useEffect(() => {
        const fetchFeed = () => {
            fetch(`${process.env.REACT_APP_API_URL}/Feed/list`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ feedId: communityId }),
            })
                .then(response => response.json())
                .then(data => setFeedItems(data.items))
                .catch(error => console.error('Error loading feed:', error));
        };

        fetchFeed();
    }, [communityId]);

    const handlePost = () => {
        if (newPost.trim() === '') return;

        fetch(`${process.env.REACT_APP_API_URL}/Feed/addFeedItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                feedId: communityId,
                content: newPost,
                type: 1,
            }),
        })
            .then(() => {
                setNewPost('');
                // Reload the feed after posting
                fetch(`${process.env.REACT_APP_API_URL}/Feed/list`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ feedId: communityId }),
                })
                    .then(response => response.json())
                    .then(data => setFeedItems(data.items))
                    .catch(error => console.error('Error loading feed:', error));

            })
            .catch(error => console.error('Error posting to feed:', error));
    };

    return (
        <div className="card card-search-box rounded">
            <div className="card-body">
                <div className="d-flex flex-row align-items-center">
                    <div className="flex-shrink-0">
                        <img src="/img/android-icon-192x192.png" alt="Profile" className="rounded-circle avatar-image" style={{ width: '40px', height: '40px' }} />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <label id="feedPostLabel" className="w-75">
                            <input
                                type="text"
                                className="form-control w-100"
                                placeholder="Write something..."
                                value={newPost}
                                onChange={(e) => setNewPost(e.target.value)}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter' && !e.ctrlKey) {
                                        e.preventDefault();
                                        handlePost();
                                    }
                                }}
                            />
                        </label>
                    </div>
                    <div className="flex-shrink-0">
                        <button className="btn btn-primary" onClick={handlePost}>Post</button>
                    </div>
                </div>
                <div id="feedItems">
                    {feedItems.map((item, index) => (
                        <div key={index}>
                            <hr className="faded-line" />
                            <div className="d-flex flex-row align-items-center mt-3">
                                <div className="flex-shrink-0">
                                    <img src={item.userPicture} alt={item.userName} className="rounded-circle avatar-image" style={{ width: '40px', height: '40px' }} />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="feedName"><a href={`/user/${item.userId}`}>{item.userName}</a></span>
                                    <span className="feedDate">{item.created}</span>
                                </div>
                                <div className="flex-shrink-0">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <div className="flex-row">{item.content}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Feed;
