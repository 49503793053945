import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClipboardJS from 'clipboard';
import '../styles/UserOverview.css';

const UserOverview = ({ currentUser }) => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [isMe, setIsMe] = useState(false);
    const [profileData, setProfileData] = useState({
        userQuote: '',
        bioBox: '',
        userName: ''
    });
    const canvasRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('fetching user data with ID: ', userId);
                const data = await fetchUserData(userId);
                setUserData(data);
                setIsMe(data.me);
                setProfileData({
                    userQuote: data.quote || '',
                    bioBox: data.biography || '',
                    userName: data.name || ''
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();

        const storedData = localStorage.getItem(`profileData-${userId}`);
        if (storedData) {
            setProfileData(JSON.parse(storedData));
        }
    }, [userId]);

    useEffect(() => {
        new ClipboardJS('.btn-clipboard');
    }, []); // Empty dependency array ensures it runs only once

    useEffect(() => {
        if (userData && isMe) {
            initializeCanvasAnimation(userData);
        }
    }, [userData, isMe]);

    const fetchUserData = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/User/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ id: id }) // Ensure the id is included in the body
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null; // Handle the error as needed, e.g., return null or an empty object
        }
    };


    const initializeCanvasAnimation = (data) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let isAnimating = true;
        let centerPhotoRadius = 50;
        let firstRingAngle = 0;
        let secondRingAngle = 0;
        const membersArray = Object.values(data.referrals.members);
        const relocatedArray = Object.values(data.referrals.relocated);

        const firstRingPhotos = membersArray.slice(0, 6);
        const secondRingPhotos = relocatedArray.slice(0, 12);

        const drawPhotos = () => {
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;

            const firstRingRadius = Math.min(canvas.width, canvas.height) / 4.2;
            const secondRingRadius = Math.min(canvas.width, canvas.height) / 2.4;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const drawRing = (photos, ringRadius, photoRadius, angleOffset) => {
                const angleStep = (Math.PI * 2) / photos.length;
                photos.forEach((photo, index) => {
                    const angle = angleStep * index + angleOffset;
                    const x = centerX + ringRadius * Math.cos(angle);
                    const y = centerY + ringRadius * Math.sin(angle);
                    drawCircleImage(ctx, photo.picture.thumb, x, y, photoRadius);
                });
            };

            drawRing(firstRingPhotos, firstRingRadius, centerPhotoRadius / 1.5, firstRingAngle);
            drawRing(secondRingPhotos, secondRingRadius, centerPhotoRadius / 1.8, secondRingAngle);
            drawCircleImage(ctx, data.picture.thumb, centerX, centerY, centerPhotoRadius);
        };

        const drawCircleImage = (ctx, imageSrc, x, y, radius) => {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                ctx.save();
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, Math.PI * 2, true);
                ctx.closePath();
                ctx.clip();
                ctx.drawImage(img, x - radius, y - radius, radius * 2, radius * 2);
                ctx.restore();
            };
        };

        const animate = () => {
            if (isAnimating) {
                firstRingAngle += 0.003;
                secondRingAngle -= 0.002;
            }
            drawPhotos();
            animationRef.current = requestAnimationFrame(animate);
        };

        const stopAnimation = () => {
            isAnimating = false;
            drawPhotos();
        };

        const startAnimation = () => {
            console.log('Starting animation');
            isAnimating = true;
            drawPhotos();
        };

        canvas.addEventListener('mouseover', stopAnimation);
        canvas.addEventListener('mouseout', startAnimation);

        drawPhotos();
        animate();
        startAnimation();

        return () => {
            cancelAnimationFrame(animationRef.current);
            canvas.removeEventListener('mouseover', stopAnimation);
            canvas.removeEventListener('mouseout', startAnimation);
        };
    };

    const saveField = async (field, value) => {
        if (field === 'name' && value.length < 4) {
            return;
        }
        await fetch(`${process.env.REACT_APP_API_URL}/User/updateField`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ id: userId, field: field, value: value })
        });
        const updatedData = { ...profileData, [field]: value };
        setProfileData(updatedData);
        localStorage.setItem(`profileData-${userId}`, JSON.stringify(updatedData));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
        localStorage.setItem(`profileData-${userId}`, JSON.stringify({ ...profileData, [name]: value }));
    };

    const handleAvatarUpload = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const avatarImage = e.target.result;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/User/uploadAvatar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ id: userId, avatar: avatarImage })
            });
            const data = await response.json();
            localStorage.setItem('avatar', data.picture.thumb);
            setUserData({ ...userData, picture: data.picture });
        };
        reader.readAsDataURL(file);
    };

    const handleAddFriend = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/User/addFriend`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ friendId: userId })
        });
        alert('Friend request sent');
    };

    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <div id="userOverviewContainer" className="container-fluid">
            <div className="row">
                <div className="col-md-3 order-0" id="canvasContainer">
                    <div className="p-5">
                        <div className="portrait rounded-circle">
                            <img src={userData.picture.thumb} className="card-img-top rounded-circle" alt={userData.name} />
                            {isMe && <button className="btn w-100 uploadAvatar" onClick={() => document.getElementById('fileUpload').click()}>
                                <i className="fas fa-camera fa-2x"></i>
                            </button>}
                            <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={handleAvatarUpload} />
                        </div>
                    </div>
                    <h3 className="card-title userName" contentEditable={isMe} suppressContentEditableWarning onBlur={(e) => saveField('name', e.target.innerText)}>{profileData.userName}</h3>
                    <canvas ref={canvasRef} className="w-100" />
                </div>
                <div className="col-md-9 order-1">
                    <div className="row shadow-sm profileBar p-3 order-2">
                        <div className="col-md-3">
                            <img src="/img/apple-icon-precomposed.png" className="card-img-top rounded-circle p-5" alt={userData.activeCommunity ?? 'No community'} />
                            <h5>{userData.activeCommunity}</h5>
                        </div>
                        <div className="col-md-3">
                            {(userData.activeCourse) ? (
                                    <>
                            <a href={`/courseoverview/${userData.activeCourse.id}`}><img src={`/img/${userData.activeCourse.image}`} className="card-img-top rounded-circle p-5" alt={userData.activeCourse.name} /></a>
                            <a href={`/courseoverview/${userData.activeCourse.id}`} className="header-h5">{userData.activeCourse.name}</a>
                                    </>
                            ) : (
                            <>
                                <img src="/img/apple-icon-precomposed.png" className="card-img-top rounded-circle p-5" alt="No course" />
                                <h5>No course</h5>
                            </>
                        )}
                        </div>
                        <div className="col-md-3">
                            <img src="/img/apple-icon-precomposed.png" className="card-img-top rounded-circle p-5" alt={userData.name} />
                            <h5>Seminar</h5>
                        </div>
                        <div className="col-md-3 text-center">
                            {!isMe && (
                                <>
                                    <button className="btn btn-primary w-75 m-3 sendMessage"><i className="fas fa-envelope"></i> Message</button>
                                    <button className="btn btn-primary w-75 m-1 addFriend" onClick={handleAddFriend}><i className="fas fa-user-plus"></i> Add Friend</button>
                                    <button data-clipboard-text={userData.referralLink} className="btn btn-clipboard btn-primary w-75 m-3">Copy referral link</button>
                                    <button className="btn btn-danger w-75 m-1 blockUser"><i className="fas fa-user-slash"></i> Block User</button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="row order-1">
                        <div className="col-md-12 userQuote my-3" contentEditable={isMe} suppressContentEditableWarning onBlur={(e) => saveField('quote', e.target.innerText)}>{profileData.userQuote}</div>
                    </div>
                    <div className="row order-3">
                        <div className="col-md-12 bioBox p-3" contentEditable={isMe} suppressContentEditableWarning onBlur={(e) => saveField('biography', e.target.innerText)}>{profileData.bioBox}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 referralBox mt-3 p-3">
                            <h5>Referrals</h5>
                            <div id="referralBox">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Commission rate</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.values(userData.referrals.total).length > 0 ? (
                                    Object.values(userData.referrals.total).map(member => (
                                        <tr key={member.id}>
                                            <td>{member.name}</td>
                                            <td>{member.commissionRate ?? '20'}%</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2">No referrals yet</td>
                                    </tr>
                                )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 achivementBox my-3 p-3">
                            <h5>Achievements</h5>
                            <div id="achievementBox">
                                <p>No achievements yet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserOverview;
