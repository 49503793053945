// App.js is the main component that renders the application. It uses the BrowserRouter component from react-router-dom to manage the application's routes. The routes are defined using the Route component, which specifies the path and the component to render when the path matches. The Header and Footer components are also included in the layout.

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Main';
import Marketplace from './pages/Marketplace';
import Community from './pages/Community';
import ShoppingCart from './pages/ShoppingCart';
import CourseEditor from './pages/CourseEditor';
import CourseOverview from "./pages/CourseOverview";
import UserOverview from "./pages/UserOverview";
import Pricing from "./pages/Pricing";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './context/AuthContext';
import './styles/FontAwesome.css';
import './styles/Community.css';
import ModularCourseEditor from "./pages/ModularCourseEditor";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/marketplace" element={<Marketplace />} />
                    <Route path="/community/*" element={<Community />} />
                    <Route path="/cart" element={<ShoppingCart />} />
                    <Route path="/courseeditor/:courseId" element={<CourseEditor />} />
                    <Route path="/course/editor/:courseId" element={<ModularCourseEditor />} />
                    <Route path="/courseoverview/:courseId" element={<CourseOverview />} />
                    <Route path="/user/:userId" element={<UserOverview />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/signup/:referralType/:referralId" element={<Signup />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </Router>
        </AuthProvider>
    );
}

export default App;
