// src/pages/Marketplace.js

import React, { useEffect, useState } from 'react';

const Marketplace = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Fetch marketplace items on component mount
        fetch(`${process.env.REACT_APP_API_URL}/Marketplace/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setItems(data);
            })
            .catch(error => console.error('Error fetching marketplace data:', error));
    }, []);

    const addToCart = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/addItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                itemId: id,
                quantity: 1,
            }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Item added to cart:', data);
                // Optionally, trigger a cart update or notification
            })
            .catch(error => console.error('Error adding item to cart:', error));
    };

    const createNewCourse = () => {
        // Implement course creation logic here
        console.log('Create new course');
    };

    return (
        <div className="row card-container" id="marketplace">
            {items.map(item => (
                <div className="col-md-2 p-1" key={item.id}>
                    <div className="card h-100 d-flex flex-column g-0">
                        <div className="card-img">
                            <img src={item.image} className="img-fluid rounded-top" alt={item.title} />
                        </div>
                        <div className="card-body d-flex flex-column justify-content-between">
                            <div>
                                <h5 className="card-title">{item.title}</h5>
                                <p className="card-text">{item.description}</p>
                            </div>
                            <div>
                                <p className="card-text"><small className="text-muted">Price: {item.price}</small></p>
                                <button
                                    className="btn btn-primary addToCart"
                                    onClick={() => addToCart(item.id)}
                                >
                                    <i className="fa fa-cart-plus"></i> Add to Cart
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* Create your own course ad banner */}
            <div className="col-md-2 p-1">
                <div className="card h-100 d-flex flex-column g-0">
                    <div className="card-img">
                        <img src="https://via.placeholder.com/300x200" className="img-fluid rounded-top" alt="Create your own course" />
                    </div>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                            <h5 className="card-title">Create your own course</h5>
                            <p className="card-text">Create your own course and sell it on the marketplace</p>
                        </div>
                        <div>
                            <p className="card-text"><small className="text-muted">Price: Anything you want</small></p>
                            <button
                                className="btn btn-primary create-course"
                                onClick={createNewCourse}
                            >
                                <i className="fa fa-plus"></i> Create course
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Marketplace;
