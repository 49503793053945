// src/components/editor/HeadingModule.js

import React from 'react';

const HeadingModule = ({ data }) => {
    return (
        <div className="heading-module">
            <h2>{data}</h2>
        </div>
    );
};

export default HeadingModule;
