// src/pages/Main.js

import React from 'react';

import '../styles/Main.css'; // Import the styles from main.php

const Main = () => {
    return (
        <>
            <div id="section1" className="section section-1">
                <div className="container">
                    <h1>Welcome to Your Wellness Community</h1>
                    <p>A Journey to Wellness, Together.</p>
                </div>
            </div>

            <div id="section2" className="section section-2">
                <div className="container">
                    <h2>What We Offer</h2>
                    <p>Community, Chat, Video Conferencing, Feed, Marketplace, and more.</p>
                </div>
            </div>

            <div id="section3" className="section section-3">
                <div className="container">
                    <h2>Join with fellow wellness seekers</h2>
                    <p>Share your journey, learn from others, and grow together.</p>
                </div>
            </div>

            <div id="section4" className="section section-4">
                <div className="container text-center">
                    <a href="/signup" className="btn btn-success btn-lg mx-auto w-50">Sign Up Now</a>
                </div>
            </div>
        </>
    );
};

export default Main;
