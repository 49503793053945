// src/components/editor/TextModule.js

import React, { useEffect, useRef } from 'react';
import Quill from 'quill';

const TextModule = ({ data }) => {
    const quillRef = useRef(null);

    useEffect(() => {
        const quillInstance = new Quill(quillRef.current, {
            theme: 'snow',
        });

        quillInstance.root.innerHTML = data;
    }, [data]);

    return <div ref={quillRef} className="text-module"></div>;
};

export default TextModule;
