// src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';

const Dashboard = () => {
    const [communities, setCommunities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [seminars, setSeminars] = useState([]);

    useEffect(() => {
        // Fetch data on component mount
        fetch(`${process.env.REACT_APP_API_URL}/User/dashboard`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCommunities(data.communities || []);
                setCourses(data.courses || []);
                setSeminars(data.seminars || []);
            })
            .catch(error => console.error('Error loading dashboard:', error));
    }, []);

    const handleCreateCommunity = () => {
        fetch(`${process.env.REACT_APP_API_URL}/Community/newCommunity`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.communityCode) {
                    window.location.href = `/community/${data.communityCode}`;
                }
            });
    };

    const handleCreateCourse = () => {
        fetch(`${process.env.REACT_APP_API_URL}/Course/newCourse`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (!isNaN(data) && data > 0) {
                    window.location.href = `/courseeditor/${data}`;
                }
            });
    };

    const renderCommunities = () => {
        if (communities.length === 0) {
            return <div className="col-md-4 mb-3">No communities found. Visit the market to join some.</div>;
        }

        return communities.map(community => (
            <div className="col-md-4 mb-3" key={community.communityCode}>
                <div className="card">
                    <div className="card-body communityClick" data-id={community.communityCode}>
                        <h5 className="card-title fw-bold">{community.name}</h5>
                        <p className="card-text">{community.description}</p>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary" onClick={() => window.location.href = `/community/${community.communityCode}`}>View</button>
                        <button className="btn btn-success"><i className="fa-solid fa-user-plus"></i> Join</button>
                    </div>
                </div>
            </div>
        ));
    };

    const renderCourses = () => {
        if (courses.length === 0) {
            return <div className="col-md-4 mb-3">No courses found. Visit the market to join some.</div>;
        }

        return courses.map(course => (
            <div className="col-md-4 mb-3" key={course.id}>
                <div className="card h-100 d-flex flex-column g-0">
                    <div className={`card-ribbon ${course.salePrice && course.salePrice !== course.price ? 'sale' : course.price === "0.00" ? 'free' : 'normal'}`}>
                        <span>{course.price === "0.00" ? "Free" : `$${parseFloat(course.salePrice || course.price).toFixed(2)}`}</span>
                    </div>
                    <a href={`/courseoverview/${course.id}`}>
                        <img src={course.image ? `/img/${course.image}` : '/img/300x200.png'} alt={course.name || 'Untitled course'} className="card-img-top" />
                    </a>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                            <h5 className="card-title fw-bold">{course.name || 'Untitled course'}</h5>
                            <p className="card-text">{course.description || 'No description available'}</p>
                        </div>
                        <div>
                            <h6 className="fw-bold">Table of contents</h6>
                            <ul className="list-group">
                                {course.toc.slice(0, 4).map((tocItem, index) => (
                                    <li className="list-group-item" key={index}>{tocItem.name}: {tocItem.description}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="card-footer">
                        <a href={`/courseoverview/${course.id}`} className="btn btn-primary"><i className="fa-solid fa-eye"></i></a>
                        {course.ownerId === localStorage.getItem('userId') ? (
                            <a href={`/courseeditor/${course.id}`} className="btn btn-primary"><i className="fa-solid fa-pencil"></i></a>
                        ) : (
                            <>
                                <button className="btn btn-success"><i className="fa-solid fa-sack-dollar"></i></button>
                                <button className="btn btn-primary"><i className="fa-solid fa-cart-plus"></i></button>
                            </>
                        )}
                        <button className="btn btn-danger"><i className="fa-solid fa-heart"></i></button>
                    </div>
                </div>
            </div>
        ));
    };

    const renderSeminars = () => {
        if (seminars.length === 0) {
            return <div className="col-md-4 mb-3">No seminars found. Visit the market to join some.</div>;
        }

        return seminars.map(seminar => (
            <div className="col-md-4 mb-3" key={seminar.id}>
                <div className="card">
                    <div className={`card-ribbon ${seminar.salePrice && seminar.salePrice !== seminar.price ? 'sale' : seminar.price === "0.00" ? 'free' : 'normal'}`}>
                        <span>{seminar.price === "0.00" ? "RSVP" : `$${parseFloat(seminar.salePrice || seminar.price).toFixed(2)}`}</span>
                    </div>
                    <a href={`/seminaroverview/${seminar.id}`}>
                        <img src={seminar.image ? `/img/${seminar.image}` : '/img/300x200.png'} alt={seminar.name || 'Untitled seminar'} className="card-img-top" />
                    </a>
                    <div className="card-body">
                        <h5 className="card-title fw-bold">{seminar.name}</h5>
                        <p className="card-text">{seminar.preview}</p>
                    </div>
                    <div className="card-footer">
                        <a href={`/seminaroverview/${seminar.id}`} className="btn btn-primary">View</a>
                        <button className="btn btn-success"><i className="fa-solid fa-cloud"></i> RSVP</button>
                        <button className="btn btn-danger"><i className="fa-solid fa-heart"></i></button>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="container-fluid py-4">
            <div className="row">
                <div className="col-md-9">
                    <h2 className="fw-bold">My webinars</h2>
                    <div className="row" id="webinarsRow">
                        {renderSeminars()}
                    </div>
                    <h2 className="fw-bold mt-5">My courses</h2>
                    <div className="row" id="coursesRow">
                        {renderCourses()}
                        <div className="col-md-4 mb-3">
                            <div className="card h-100 d-flex flex-column g-0">
                                <img src="/img/300x200.png" className="img-fluid rounded-top" alt="Create your own course" />
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <div>
                                        <h5 className="card-title fw-bold">Create your own course</h5>
                                        <p className="card-text">Create your own course and sell it on the marketplace</p>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary create-course" onClick={handleCreateCourse}><i className="fa fa-plus"></i> Create course</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="fw-bold mt-5">My communities</h2>
                    <div className="row" id="communitiesRow">
                        {renderCommunities()}
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body"> <h5 className="card-title">Create a community</h5> <p className="card-text">Create your own community and invite others to join</p> </div> <div className="card-footer"> <button className="btn btn-primary create-community" onClick={handleCreateCommunity}><i className="fa fa-plus"></i> Create community</button> </div> </div> </div> </div> </div> <div className="col-md-3"> <h2 className="fw-bold">My friends</h2> {/* Add friends content /} <h2 className="fw-bold">Nearby community members</h2> {/ Add nearby community members content */}
                            </div>
                        </div>
                    </div>
            );
};

export default Dashboard;