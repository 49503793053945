// src/components/editor/VideoModule.js

import React from 'react';

const VideoModule = ({ data }) => {
    return (
        <div className="video-module">
            <iframe
                width="560"
                height="315"
                src={data}
                title="Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default VideoModule;
