// src/components/Footer.js

import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const Footer = () => {
    const { isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        if (isLoggedIn) {
            loadMiniCart();
            refreshUserData();
        }
    }, [isLoggedIn]);

    const loadMiniCart = () => {
        fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/getTotalItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                document.getElementById('shoppingCount').textContent = data;
            })
            .catch(error => console.error('Error loading mini cart:', error));
    };

    const refreshUserData = () => {
        fetch(`${process.env.REACT_APP_API_URL}/User/${localStorage.getItem('userId')}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('avatar', data.avatar);
                localStorage.setItem('firstName', data.givenName);
                const lastNavLink = document.querySelector('.avatar');
                if (lastNavLink) {
                    lastNavLink.innerHTML = `<img src="${data.avatar}" alt="${data.givenName}" class="avatar" />`;
                }
            })
            .catch(error => console.error('Error refreshing user data:', error));
    };


    return (
        <footer>
            {/* Additional footer content */}
        </footer>
    );
};

export default Footer;
