import React, { useEffect, useState } from 'react';

const ShoppingCart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);

    useEffect(() => {
        // Fetch cart items on component mount
        fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/getItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                loadCartItems(data);
            })
            .catch(error => {
                console.error('Error fetching cart items:', error);
            });
    }, []);

    const loadCartItems = (items) => {
        let total = 0;
        if (items.length === 0) {
            window.location.href = '/marketplace';
        }
        items.forEach(item => {
            total += item.price * item.quantity;
        });
        setCartItems(items);
        setCartTotal(total);
    };

    const handleRemoveItem = (itemId, itemSource, quantity) => {
        fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/removeItem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ itemId, itemSource, quantity })
        })
            .then(response => response.json())
            .then(data => {
                loadCartItems(data);
            })
            .catch(error => {
                console.error('Error removing item:', error);
            });
    };

    return (
        <div className="row">
            <div className="col-md-2 order-md-1 order-2">
                {/* Sidebar with address details */}
            </div>
            <div className="col-md-7 order-md-2 order-1" id="mainCart">
                <h1>Shopping Cart</h1>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Pic</th>
                                <th scope="col">Item</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total</th>
                                <th scope="col">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cartItems.map((item, index) => (
                                <tr key={index}>
                                    <td><img src={item.image} alt={item.name} className="img-thumbnail" style={{ width: '50px' }} /></td>
                                    <td>{item.name}</td>
                                    <td>{item.price}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price * item.quantity}</td>
                                    <td>
                                        <button className="btn btn-danger" onClick={() => handleRemoveItem(item.itemId, item.itemSource, item.quantity)}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Total: <span>{cartTotal}</span></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn btn-primary" id="checkoutButton">Checkout</button>
                    </div>
                </div>
            </div>
            <div className="col-md-3 order-md-3 order-3">
                {/* Sidebar with payment details */}
            </div>
        </div>
    );
};

export default ShoppingCart;
