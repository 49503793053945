// src/pages/Community.js

import React from 'react';
import { useParams } from 'react-router-dom';
import Conference from '../components/community/Conference';
import ConferenceChat from '../components/community/ConferenceChat';
import Feed from '../components/community/Feed';
import Sidebar from '../components/community/Sidebar';
import SidebarRight from '../components/community/SidebarRight';

const Community = () => {
    let { id: communityId } = useParams();
    const action = 'main'; // Dynamically determine action based on URL or other logic
    if (communityId === undefined) {
        communityId = 1;
    }
    return (
        <div className="row">
            <div className="col-md-2 order-md-1 order-2">
                <Sidebar communityId={communityId} />
            </div>
            <div className="col-md-7 order-md-2 order-1" id="mainWindow">
                {action === 'conference' && <Conference communityId={communityId} />}
                {action === 'chat' && <ConferenceChat communityId={communityId} />}
                {action === 'main' && <Feed communityId={communityId} />}
                {/* Add more conditions for other sections as needed */}
            </div>
            <div className="col-md-3 order-md-3 order-3">
                {action === 'conference' ? <ConferenceChat communityId={communityId} /> : <SidebarRight communityId={communityId} />}
            </div>
        </div>
    );
};

export default Community;
