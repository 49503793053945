// src/components/editor/ChatModule.js

import React from 'react';

const ChatModule = ({ data }) => {
    return (
        <div className="chat-module">
            <h3>Chat Section</h3>
            <p>This is a placeholder for the chat module.</p>
            {/* Placeholder for chat content */}
            <div className="chat-window">
                <div className="chat-message">User1: [Placeholder Message]</div>
                <div className="chat-message">User2: [Placeholder Message]</div>
            </div>
            <input type="text" placeholder="Type a message..." className="chat-input" />
        </div>
    );
};

export default ChatModule;
