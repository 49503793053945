// src/components/editor/ImageModule.js

import React from 'react';

const ImageModule = ({ data }) => {
    return (
        <div className="image-module">
            <h3>Image Section</h3>
            <p>This is a placeholder for the image module.</p>
            {/* Placeholder for image content */}
            <div className="image-placeholder">
                <img src="https://via.placeholder.com/300x200" alt="Placeholder" />
                <p>[Placeholder Image Description]</p>
            </div>
        </div>
    );
};

export default ImageModule;
