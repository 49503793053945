// src/components/community/SidebarRight.js

import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

const SidebarRight = ({ communityId, action }) => {
    const [community, setCommunity] = useState({ name: '', description: '', myCode: '', memberCount: 0, feedId: 0 });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/Community/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ id: communityId }),
        })
            .then(response => response.json())
            .then(data => setCommunity(data))
            .catch(error => console.error('Error fetching community data:', error));
    }, [communityId]);

    return (
        <div className="card">
            <div className="card-body">
                <h3>{community.name}</h3>
                <p>
                    {community.description}
                    <br/>
                    <Link to={`/signup/us/${community.myCode}`}>
                        Registration link
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default SidebarRight;
