import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/Signup.css';

const Signup = () => {
    const { referralType, referralId } = useParams(); // Get optional referral parameters
    const [portrait, setPortrait] = useState(null);
    const [portraitPreview, setPortraitPreview] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({ color: 'rgb(108, 117, 125)', message: '' });
    const [referrals, setReferrals] = useState({});
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });

    const communityId = referrals.community || '';

    // Fetch referral data if both referralType and referralId are present
    useEffect(() => {
        if (referralId && referralType) {
            fetchReferralData(referralId, referralType);
        }
    }, [referralId, referralType]);

    const fetchReferralData = (referralId, referralType) => {
        const API_URL = process.env.REACT_APP_API_URL;
        const API_TOKEN = process.env.REACT_APP_API_TOKEN;
        fetch(`${API_URL}/Auth/referralLoad`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': API_TOKEN,
            },
            body: JSON.stringify({
                referralId: referralId,
                referralType: referralType,
            }),
        })
            .then(response => response.json())
            .then(data => {
                setReferrals(data); // Update referrals data
                document.getElementById('welcomeText').innerText = `Welcome to join ${data.name}`;
                document.getElementById('registerInfo').innerText = data.description;
            })
            .catch(error => {
                console.error('Error fetching referral data:', error);
            });
    };

    // Handle file changes for portrait upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = 1024;
                    canvas.height = 1024;
                    ctx.drawImage(img, 0, 0, 1024, 1024);
                    setPortraitPreview(canvas.toDataURL('image/png'));
                    setPortrait(file);
                    setLoading(false);
                };
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle password input changes
    const handlePasswordChange = (e) => {
        const password = e.target.value;
        let strength = { color: 'red', message: 'Weak' };
        if (password.length > 5 && /[A-Z]/.test(password) && /[0-9]/.test(password)) {
            strength = { color: 'orange', message: 'Moderate' };
        }
        if (password.length > 8 && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password)) {
            strength = { color: 'green', message: 'Strong' };
        }
        setPasswordStrength(strength);
        setFormData({ ...formData, password });
    };

    // Handle form data changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!portrait || !formData.email || !formData.password || !formData.firstName || !formData.lastName) {
            alert('Please fill in all fields.');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;

            fetch(`${process.env.REACT_APP_API_URL}/Auth/Register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                },
                body: JSON.stringify({
                    ...formData,
                    referralId,
                    referralType,
                    autoLogin: 1,
                    portrait: base64data,
                    communityId,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        alert(data.error);
                    } else if (data.token) {
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('userId', data.userId);
                        localStorage.setItem('avatar', data.avatar);
                        localStorage.setItem('firstName', data.givenName);
                        window.location.href = isNaN(communityId) ? '/dashboard' : `/community/${data.communityCode}`;
                    }
                });
        };
        reader.readAsDataURL(portrait);
    };

    return (
        <div className="container text-center">
            <h2 id="welcomeText">Welcome</h2>
            <p id="registerInfo">Register to join our community</p>
            <form onSubmit={handleSubmit} id="registrationForm">
                {step === 1 && (
                    <>
                        <div className="row registrationStep" id="step1">
                            <div id="portraitPreview" onClick={() => document.getElementById('portrait').click()} style={{ backgroundImage: `url(${portraitPreview})` }}>
                                {!portrait && <span id="portraitText">Click to add picture</span>}
                                {loading && <div id="spinner" className="spinner-border text-primary" role="status"><span className="sr-only">Loading...</span></div>}
                            </div>

                            <input type="file" id="portrait" name="portrait" style={{ display: 'none' }} onChange={handleFileChange} />

                            <div className="row form-row mx-auto">
                                <div className="col-6 mb-3">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="row form-row mx-auto">
                                <div className="col-6 mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handlePasswordChange} required />
                                        <button className="btn btn-outline-secondary toggle-password" type="button" onClick={() => document.getElementById('password').type = document.getElementById('password').type === 'password' ? 'text' : 'password'}>
                                            <i className="fas fa-fw fa-eye" style={{ color: passwordStrength.color }} title={`${passwordStrength.message} password`}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-row mx-auto">
                                <div className="col mb-3">
                                    <button type="submit" id="nextButton" className="btn btn-larger btn-success">Join {process.env.REACT_APP_SITE_NAME}</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {/* Additional steps can go here */}
            </form>
        </div>
    );
};

export default Signup;
