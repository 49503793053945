import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css'; // You can add your custom styles here

const NotFound = () => {
    return (
        <div className="notfound-container">
            <h1 className="notfound-title">404</h1>
            <p className="notfound-message">Uh-oh! You seem to be lost in the void of the internet.</p>
            <img
                src="/img/travolta.gif"
                alt="Confused Travolta"
                className="notfound-gif"
            />
            <p className="notfound-hint">Don’t worry, even the best of us take a wrong turn now and then.</p>
            <Link to="/" className="notfound-link">
                <button className="notfound-button">Go Back Home</button>
            </Link>
        </div>
    );
};

export default NotFound;
