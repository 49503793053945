// src/components/editor/CommentsModule.js

import React, { useState } from 'react';

const CommentsModule = ({ data }) => {
    const [comments, setComments] = useState(data.comments || []);
    const [newComment, setNewComment] = useState('');

    const handleAddComment = () => {
        if (newComment.trim()) {
            setComments([...comments, { text: newComment, author: 'CurrentUser' }]);
            setNewComment('');
        }
    };

    return (
        <div className="comments-module">
            <h3>Comments Section</h3>
            <div className="comments-list">
                {comments.length > 0 ? (
                    comments.map((comment, index) => (
                        <div key={index} className="comment">
                            <strong>{comment.author}:</strong> {comment.text}
                        </div>
                    ))
                ) : (
                    <p>No comments yet.</p>
                )}
            </div>
            <div className="comment-input">
                <input
                    type="text"
                    placeholder="Add a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                />
                <button onClick={handleAddComment}>Post</button>
            </div>
        </div>
    );
};

export default CommentsModule;
