// src/components/Header.js

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Header = () => {
    const { isLoggedIn, logout } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = () => {
        setLoading(true);

        if (email.length + password.length < 3) {
            setLoading(false);
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/Auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
            },
            body: JSON.stringify({ email, password }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('userId', data.userId);
                    localStorage.setItem('avatar', data.avatar);
                    localStorage.setItem('firstName', data.givenName);
                    window.location.href = '/dashboard';
                } else {
                    setLoading(false);
                    alert(data.message);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error logging in:', error);
            });
    };

    const handleLogout = (event) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/Auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                window.location.href = '/';
            })
            .catch(error => console.error('Error logging out:', error));
    };

    const userId = localStorage.getItem('userId');

    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light navbar-custom mb-2">
                    <div className="container-fluid">
                        {isLoggedIn ? (
                            <div className="dropdown">
                                <Link className="navbar-brand navbar-brand-custom dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="/img/icu.webp" className="communityLogo" alt="ICU" title="ICU Logo" />
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="#"><img src="/img/icu.webp" className="communityLogo" alt="Site 1" title="Site 1 Logo" /></Link></li>
                                    <li><Link className="dropdown-item" to="#"><img src="/img/icu.webp" className="communityLogo" alt="Site 2" title="Site 2 Logo" /></Link></li>
                                    <li><Link className="dropdown-item" to="#"><img src="/img/icu.webp" className="communityLogo" alt="Site 3" title="Site 3 Logo" /></Link></li>
                                    {/* Add more items as needed */}
                                </ul>
                            </div>
                        ) : (
                            <Link className="navbar-brand navbar-brand-custom" to="/">ICU</Link>
                        )}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            {
                                isLoggedIn ? (
                                    <>
                                        <form id="searchForm" className="d-flex search-box" onSubmit={(e) => e.preventDefault()}>
                                            <input type="search" id="searchBox" className="form-control" placeholder="Search for anything" />
                                        </form>
                                        <ul className="navbar-nav me-auto adjustBar">
                                            <li className="nav-item">
                                                <Link className="nav-link nav-link-custom" to="/dashboard">Dashboard</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link nav-link-custom" to="/marketplace">Marketplace</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link nav-link-custom" to="/community">Community</Link>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <ul className="navbar-nav me-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link nav-link-custom" to="#">Community</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link nav-link-custom" to="#">Education</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link nav-link-custom" to="/pricing">Pricing</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link nav-link-custom" to="#">Contact</Link>
                                        </li>
                                    </ul>
                                )
                            }

                            {isLoggedIn ? (
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link nav-link-custom" to="/cart"><i
                                            className="fa fa-cart-shopping"></i><span className="badge"
                                                                                      id="shoppingCount">0</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link nav-link-custom" to="#"><i className="fa fa-bell"></i><span
                                            className="badge">0</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <span id="logout" onClick={handleLogout}>
                                            <i className="fa fa-portal-exit"></i>
                                        </span>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link nav-link-custom" to={`/user/${userId}`}>
                                            <i className="fa fa-user"></i>
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="navbar-nav ms-auto" id="loginSnippet">
                                    <li className="nav-item dropdown">
                                        <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown"
                                              role="button" data-bs-toggle="dropdown" aria-expanded="false"
                                              data-bs-auto-close="outside">
                                            <b>Login</b>
                                        </Link>
                                        <ul id="login-dp" className="dropdown-menu dropdown-menu-end p-3">
                                            <li>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <form id="loginForm" onSubmit={(e) => e.preventDefault()}>
                                                            <div className="form-group mb-2">
                                                                <label className="sr-only" htmlFor="email">Email
                                                                    address</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="email"
                                                                    placeholder="Email address"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group mb-2">
                                                                <label className="sr-only"
                                                                       htmlFor="password">Password</label>
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="password"
                                                                    placeholder="Password"
                                                                    value={password}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    required
                                                                />
                                                                <div className="help-block text-end">
                                                                    <Link to="/reset">Forgot your password?</Link>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <button
                                                                    type="button"
                                                                    id="loginButton"
                                                                    className="btn btn-primary w-100"
                                                                    onClick={handleLogin}
                                                                >
                                                                    {loading ?
                                                                        <i className="fa fa-spinner fa-spin"></i> : 'Sign in'}
                                                                </button>
                                                            </div>
                                                        </form>
                                                        <div className="social-buttons mb-3">
                                                            <Link to="#" className="btn btn-fb"><i
                                                                className="fa-brands fa-facebook"></i></Link>
                                                            <Link to="#" className="btn btn-google"><i
                                                                className="fa-brands fa-google"></i></Link>
                                                        </div>
                                                    </div>
                                                    <div className="bottom text-center mt-3">
                                                        New here? <Link to="/signup"><b>Join Us</b></Link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </nav>
            </header>
            <script>
                const API_TOKEN = '{process.env.REACT_APP_API_TOKEN}';
                const API_URL = '{process.env.REACT_APP_API_URL}';
            </script>
        </>
    );
};

export default Header;
