// src/components/community/Sidebar.js

import React, { useEffect, useState } from 'react';

const Sidebar = ({ communityId }) => {
    const [chats, setChats] = useState([]);
    const [membersCount, setMembersCount] = useState(0);
    const [referrals, setReferrals] = useState('?');

    useEffect(() => {
        // Fetch the chats and other data
        fetch(`${process.env.REACT_APP_API_URL}/Chat/chats`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(data => setChats(data))
            .catch(error => console.error('Error loading chats:', error));

        // Fetch community members and referrals data similarly
    }, [communityId]);

    return (
        <ol className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <a className="fw-bold" href={`/community/${communityId}`}>Overview</a>
                </div>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold"><i className="fa-solid fa-fw fa-plus"></i> Chat</div>
                </div>
                <span className="badge text-bg-primary rounded-pill">14</span>
                {/* Display chat names */}
            </li>
            <div id="chatContainer">
                {chats.map(chat => (
                    <li
                        key={chat.id}
                        className="list-group-item d-flex justify-content-between align-items-start chatMember"
                        onClick={() => window.location.href = `/community/${communityId}/chat/${chat.id}`}
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-normal">{chat.name}</div>
                        </div>
                        <span className="badge text-bg-primary rounded-pill">4</span>
                    </li>
                ))}
            </div>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Events</div>
                </div>
                <span className="badge text-bg-primary rounded-pill">3</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Members</div>
                </div>
                <span className="badge text-bg-primary rounded-pill">{membersCount}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Referred</div>
                </div>
                <span className="badge text-bg-primary rounded-pill">{referrals}</span>
            </li>
        </ol>
    );
};

export default Sidebar;
