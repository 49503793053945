import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";

const CourseOverview = () => {
    const [courseData, setCourseData] = useState(null);
    const { courseId } = useParams();

    useEffect(() => {
        const fetchCourseOverview = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/Course/overview`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ id: courseId }),
                });

                const data = await response.json();
                setCourseData(data);
            } catch (error) {
                console.error('Error fetching course overview:', error);
            }
        };

        fetchCourseOverview();
    }, [courseId]);

    if (!courseData) {
        return <div>Loading...</div>;
    }

    const courseImage = courseData.image ? `/img/${courseData.image}` : '/img/300x200.png';
    const priceText = courseData.price === 0 ? 'Free' : courseData.price;

    return (
        <div id="courseOverviewContainer" className="container">
            <div className="row">
                <div className="col-md-8">
                    <h1>{courseData.name}</h1>
                    <p>{courseData.description}</p>
                    <div className="mt-3">
                        <h2>Author</h2>
                        <p>{courseData.author}</p>
                        <p>Created: {courseData.created}</p>
                        <p>Updated: {courseData.updated}</p>
                    </div>
                    <h2>Table of Contents</h2>
                    <ul className="list-group">
                        {courseData.toc.map((item, index) => (
                            <li key={index} className="list-group-item">
                                <strong>{item.name}</strong> - {item.description}
                            </li>
                        ))}
                    </ul>
                    <div className="mt-3">
                        <h2>This course contains</h2>
                        <div className="row" id="courseContainsBlock">
                            <div className="col-md-6"><i className="fa-solid fa-video"></i> 16 hours of video</div>
                            <div className="col-md-6"><i className="fa-solid fa-file"></i> 20 documents</div>
                            <div className="col-md-6"><i className="fa-solid fa-clipboard-question"></i> 10 quizzes</div>
                            <div className="col-md-6"><i className="fa-solid fa-pen"></i> 5 assignments</div>
                            <div className="col-md-6"><i className="fa-solid fa-person-chalkboard"></i> Chat with your teacher</div>
                            <div className="col-md-6"><i className="fa-solid fa-award"></i> Signed diploma</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={courseImage} alt="Course Image" className="img-fluid w-100" />
                    <div className="mt-3">
                        <p><strong>Price:</strong> {priceText}</p>
                        <p><strong>Duration:</strong> {courseData.duration}</p>
                    </div>
                    {priceText !== 'Free' && (
                        <>
                            <button className="btn btn-larger btn-success">
                                <i className="fa-solid fa-sack-dollar"></i> Buy now
                            </button>
                            <button className="btn btn-larger btn-primary">
                                <i className="fa-solid fa-cart-plus"></i> Add to cart
                            </button>
                            <button className="btn btn-larger btn-danger">
                                <i className="fa-solid fa-heart"></i> Wishlist
                            </button>
                        </>
                    )}
                    {courseData.createdBy === localStorage.getItem('userId') && (
                        <a href={`/courseeditor/${courseData.id}`} className="btn btn-larger btn-secondary">
                            <i className="fa-solid fa-pen"></i> Edit
                        </a>
                    )}
                    <div className="line-container">
                        <span className="line"></span>
                        <span className="ball"></span>
                        <span className="line"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseOverview;
